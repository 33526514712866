import $ from 'jquery';
import 'popper.js';
import 'bootstrap/js/dist/alert';
import 'bootstrap/js/dist/modal';



if (!window.HTMLPictureElement) {
    import('picturefill');
}

let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};

window.app.modules =  app.modules || {};

import initModulesInScope from "@elements/init-modules-in-scope";
app.initModules = function ($scope) {
    console.warn('\'app.initModules\' is going to be deprecated and should not be used anymore. Please import and use \'initModulesInScope\' from \'./scripts/initModulesInScope\' instead.');
    initModulesInScope($scope);
};

$.loadScript = function (url) {
    console.warn('\'$.loadScript\' is going to be deprecated and should not be used anymore. Please use \'import\' for local files or import and use \'loadScript\' for external resources.');

    return import('@elements/load-script').then(function (loadScript) {
        return loadScript.default(url);
    });
};

/**********************************
 *
 *      Project Stuff
 *
 * ********************************/

console.log('script js');

import {init as initAlertNotification, showNotification} from '@elements/alert-notification';
initAlertNotification({
    //'container': [document.body], //@todo - just a test because otherwise notification won't show
    // default options
});

import * as parsley from '@elements/parsley-bootstrap-validation';
app.modules.parsley = parsley.initInScope;

import * as actionChanger from '@elements/action-changer';
app.modules.actionChanger = actionChanger.initInScope;

import * as reloadForm from "@elements/reload-form";
app.modules.reloadForm = reloadForm.initInScope;

import * as fileInput from '@elements/file-input';
app.modules.fileInput = fileInput.initInScope;

(function( $ ){
    "use strict";

    /*
    showNotification({
        type: 'info',
        title: 'Title',
        content: 'Content',
        closable: true,
        styleModifier: 'additional-styling-class',
        icon: 'warning',
        customFiles: 'whatever'
    });
    */

    /* -> _config._preload = Load this functions first */
    if (_config['_preload']) {
        $.each( _config['_preload'], function( _key, _val ){
            if( typeof _val == 'boolean' && typeof window.app[_key] == 'function' ){
                window.app[_key]();
            }
        });
    }

    /* -> _config = Load all others (not _preload and _reload) */
    $.each( _config, function( _key, _val ){
        if( ( typeof _val == 'boolean' && typeof window.app[_key] == 'function' && _key != '_reload' && _key != '_preload' ) ){
            window.app[_key]();
        }
    });

    /* -> _config._reload = Load the ajaxInclued and others after the rest */
    if (_config['_reload']) {
        $.each( _config['_reload'], function( _key, _val ){
            if( ( typeof _val == 'boolean' && typeof window.app[_key] == 'function' ) ){
                window.app[_key]();
            }
        });
    }
    initModulesInScope();
})($);